import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import LayoutLanding from '../components/LayoutLanding'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import CarouselDrag from '../components/CarouselDrag'
import { Helmet } from 'react-helmet'
import { useLocalStorage } from '../util/useLocalStorage'
interface Props {
  data: any
  pageContext: any
}
const LandingTemplate = ({ data, pageContext }: Props) => {
  const { wpLanding, allWpTestimonial } = data

  const renderedBlocks = LazyBlocksFactory(wpLanding.lazy_data, wpLanding.title)
  const [landingPageUrl, setLandingPageUrl] = useLocalStorage(
    'landing-page-url',
    ''
  )
  const [landingPagePdf, setLandingPagePdf] = useLocalStorage(
    'landing-page-pdf',
    ''
  )

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    if (!isBrowser) {
      return
    }
    setLandingPageUrl(window.location.pathname)
    try {
      setLandingPagePdf(data.wpLanding.landing_page.pdfFile.mediaItemUrl)
    } catch {
      ;(e) => {
        console.error('No landing page pdf')
      }
    }
  }, [])

  console.log(landingPageUrl)

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <LayoutLanding data={wpLanding.landing_page}>
        {renderedBlocks.map((v) => v)}
        {wpLanding.landing_page.toggleTestimonialSlider ? (
          <CarouselDrag
            heading="What others are saying"
            data={allWpTestimonial.nodes}
            id="carousel-drag-container"
            readMore={false}
          />
        ) : (
          <></>
        )}
      </LayoutLanding>
    </>
  )
}

export default LandingTemplate

export const pageQuery = graphql`
  query LandingId($id: String!) {
    wpLanding(id: { eq: $id }) {
      title
      id
      lazy_data
      landing_page {
        toggleTestimonialSlider
        footerNav
        topPlainNav
        demoLink
        guideLink
        promoBannerTitle
        promoBannerListItemOne
        promoBannerListItemTwo
        promoBannerListItemThree
      }
    }
    allWpTestimonial {
      nodes {
        title
        slug
        acfTestimonial {
          description
          initials
          author
          vertical
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1080, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
